var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getIssuesOfProject && _vm.getSelectedProject)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('menu-title',{staticClass:"ml-4",attrs:{"title":"Project"}})],1),_c('v-card-text',[_c('v-row',{staticClass:"ma-1"},[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-col',[_vm._v(" "+_vm._s(_vm.getSelectedProject.name)+" ")])],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.getSelectedProject.description)+" ")])],1)],1)],1),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.showEditIssueDialog = true}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1),_vm._l((_vm.getIssuesOfProject),function(i){return _c('v-row',{key:i.id,staticClass:"ma-1"},[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.showEditIssueDialog = true;
                        _vm.selectedIssue = i;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit Issue")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.deleteDialog = true;
                        _vm.selectedIssue = i;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete Issue")])],1)],1)],1)],1)],1),_c('v-col',[_vm._v(" "+_vm._s(i.subject)+" ")]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s("Created at: " + new Date(i.created_on) .toLocaleString("en-GB") .substring(0, 10))+" ")]),(i.status.id == 3)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.selectedIssue = i;
                    _vm.doneDialog = true;}}},[_vm._v(" DONE ")])],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"align":'center'}},[_c('b',[_vm._v("Status:")]),_vm._v(" "+_vm._s(i.status.name)+" ")]),_c('v-col',{attrs:{"align":'center'}},[_c('b',[_vm._v("Tracker:")]),_vm._v(" "+_vm._s(i.tracker.name)+" ")]),_c('v-col',{attrs:{"align":'center'}},[_c('b',[_vm._v("Priority:")]),_vm._v(" "+_vm._s(i.priority.name)+" ")])],1),_c('v-row',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.issueJournal(i)}}},[(_vm.expandedIssue && _vm.expandedIssue.id == i.id)?_c('v-icon',[_vm._v(" mdi-arrow-up ")]):_c('v-icon',[_vm._v(" mdi-arrow-down-circle ")])],1),_c('v-col',{staticClass:"text-pre-wrap"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.replaceNewLines(i.description))}})])],1),(
                _vm.expandedIssue &&
                _vm.expandedIssue.id == i.id &&
                _vm.expandedIssue.journals &&
                !_vm.expandedIssue.is_private
              )?_c('v-row',[(!_vm.expandedIssue.private_notes)?_c('v-col',_vm._l((_vm.expandedIssue.journals),function(i){return _c('v-card',{key:i.id,staticClass:"mb-3"},[_c('v-card-title',[_vm._v(" "+_vm._s(i.user.name)+" ")]),_c('v-card-text',{staticClass:"text-pre-wrap"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.replaceNewLines(i.notes))}})])],1)}),1):_vm._e()],1):_vm._e()],1),(_vm.doneDialog)?_c('accept-dialog',{attrs:{"title":"Issue done?","text":"Are you sure you want to finnish this issue?"},on:{"accepted":function($event){return _vm.issueDone(_vm.selectedIssue)}},model:{value:(_vm.doneDialog),callback:function ($$v) {_vm.doneDialog=$$v},expression:"doneDialog"}}):_vm._e(),(_vm.deleteDialog)?_c('accept-dialog',{attrs:{"title":"Delete Issue?","text":"Are you sure you want to delete this issue?"},on:{"accepted":function($event){return _vm.delIssue(_vm.getSelectedProject.id)}},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}):_vm._e()],1)],1)],1)})],2),_c('v-dialog',{model:{value:(_vm.showEditIssueDialog),callback:function ($$v) {_vm.showEditIssueDialog=$$v},expression:"showEditIssueDialog"}},[(_vm.showEditIssueDialog)?_c('editIssuesDialog',{attrs:{"issue":_vm.selectedIssue,"project_id":_vm.getSelectedProject.id},on:{"edit-done":function($event){_vm.showEditIssueDialog = false;
        _vm.selectedIssue = {};}}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }