<template>
  <v-card>
    <v-card-title>
      <menu-title :title="issue ? 'Edit Issue' : 'Add Issue'"></menu-title>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-text-field
            hide-details
            label="Subject*"
            v-model="tmpIssue.subject"
            outlined
            :rules="[requiredRule]"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2">
          <v-select
            outlined
            v-model="tmpIssue.tracker"
            :items="getAllTrackers"
            :item-text="(item) => item.name"
            :item-value="(item) => item"
            label="Topic"
          ></v-select>
        </v-col>
        <v-col cols="2" v-if="isAdmin()">
          <v-select
            outlined
            v-model="tmpIssue.status"
            :items="getAllStatuses"
            :item-text="(item) => item.name"
            :item-value="(item) => item"
            label="Status"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            v-model="tmpIssue.priority"
            :items="getAllPriorities"
            :item-text="(item) => item.name"
            :item-value="(item) => item"
            label="Priority"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-textarea
            hide-details
            rows="1"
            auto-grow
            label="Description"
            v-model="tmpIssue.description"
            outlined
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-if="issue"
            hide-details
            rows="1"
            auto-grow
            label="Notes*"
            v-model="tmpIssue.notes"
            outlined
            :rules="[requiredRule]"
          ></v-textarea>
        </v-col>
        <v-col cols="3" v-if="!issue">
          <div
            class="base-image-input"
            :style="{ 'background-image': `url(${imageData})` }"
            @click="chooseImage"
          >
            <span v-if="!imageData" class="placeholder"> Choose an Image </span>
            <input
              class="file-input"
              ref="fileInput"
              type="file"
              @input="onSelectFile"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        outlined
        @click="
          tmpIssue = null;
          $emit('edit-done');
        "
      >
        <span>Cancel</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="issue ? editIssue() : addIssue()">
        <span>{{ issue ? "Save" : "Add" }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "../../../helpers/menu_title.vue";
import { verify, decode } from "../../../../helpers/jwt";

export default {
  name: "applicationDialog",
  components: {
    menuTitle,
  },
  props: {
    issue: {
      type: Object,
      required: false,
    },
    project_id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getNewIssue: "getNewIssue",
      getSelectedProject: "getSelectedProject",
      getAllStatuses: "getAllStatuses",
      getAllPriorities: "getAllPriorities",
      getAllTrackers: "getAllTrackers",
    }),
  },
  data: () => ({
    tmpIssue: {},
    requiredRule: (value) => !!value || "Required",
    imageData: null,
    
  }),
  async mounted() {
    await this.fetchAllStatuses();
    await this.fetchAllPriority();
    await this.fetchAllTracker();
    if (this.issue) {
      this.tmpIssue = this.issue;
    } else {
      this.tmpIssue = this.getNewIssue;
    }
  },
  methods: {
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        this.tmpIssue.filename = files[0].name;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    },

    ...mapActions({
      postIssue: "postIssue",
      updateIssue: "updateIssue",
      addIssueToProjectLocal: "addIssueToProjectLocal",
      showSnackbar: "showSnackbar",
      fetchAllPriority: "fetchAllPriority",
      fetchAllStatuses: "fetchAllStatuses",
      fetchAllTracker: "fetchAllTracker",
    }),
    isAdmin() {
      return verify(localStorage.getItem("jwt")).is_admin;
    },
    editIssue: function () {
      this.tmpIssue.subject = this.tmpIssue.subject.trim();
      this.tmpIssue.description = this.tmpIssue.description.trim();
      this.tmpIssue.project_id = this.project_id;
      this.updateIssue(this.tmpIssue).then((error) => {
        if (error) {
          this.showSnackbar("Issue could not be updated. Error: " + error);
        } else {
          this.showSnackbar("Issue has been updated");
          this.$emit("edit-done");
        }
      });
    },
    addIssue: function () {
      this.tmpIssue.username = verify(localStorage.getItem("jwt")).name;
      this.tmpIssue.project_id = this.project_id;
      console.log(this.$refs.fileInput.files);
      if (this.$refs.fileInput.files.length > 0) {
        if (new RegExp("^image/*").test(this.$refs.fileInput.files[0].type)) {
          this.tmpIssue.filedata = this.imageData.split(",")[1];
        } else {
          this.showSnackbar("Only Images can be uploaded");
        }
      }
      this.postIssue(this.tmpIssue).then((maybeError) => {
        if (maybeError.error) {
          this.showSnackbar(
            "Issue could not be added. Error: " + maybeError.error
          );
        } else {
          this.showSnackbar("Issue has been added");
          this.tmpIssue = this.getNewIssue;
          this.$emit("edit-done");
        }
      });
    },
  },
};
</script>
  
<style scoped>
.base-image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.file-input {
  display: none;
}
</style>