<template>
  <v-card flat v-if="getIssuesOfProject && getSelectedProject">
    <v-card-title>
      <menu-title class="ml-4" title="Project"> </menu-title>
    </v-card-title>
    <v-card-text>
      <v-row class="ma-1">
        <v-col>
          <v-card>
            <v-card-title>
              <v-col>
                {{ getSelectedProject.name }}
              </v-col>
            </v-card-title>
            <v-card-text>
              {{ getSelectedProject.description }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-btn @click="showEditIssueDialog = true" class="mb-2" color="primary">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-row>
      <v-row class="ma-1" v-for="i in getIssuesOfProject" :key="i.id">
        <v-col>
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="1">
                  <v-menu
                    offset-y
                    open-on-hover
                    transition="slide-y-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text v-on="on" v-bind="attrs">
                        <v-icon color="primary">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        link
                        @click="
                          showEditIssueDialog = true;
                          selectedIssue = i;
                        "
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Edit Issue</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="
                          deleteDialog = true;
                          selectedIssue = i;
                        "
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Delete Issue</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col>
                  {{ i.subject }}
                </v-col>
                <v-col cols="4">
                  {{
                    "Created at: " +
                    new Date(i.created_on)
                      .toLocaleString("en-GB")
                      .substring(0, 10)
                  }}
                </v-col>
                <v-col cols="2" v-if="i.status.id == 3">
                  <v-btn
                    color="primary"
                    outlined
                    @click="
                      selectedIssue = i;
                      doneDialog = true;
                    "
                  >
                    DONE
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col :align="'center'">
                  <b>Status:</b> {{ i.status.name }}
                </v-col>
                <v-col :align="'center'">
                  <b>Tracker:</b> {{ i.tracker.name }}
                </v-col>
                <v-col :align="'center'">
                  <b>Priority:</b> {{ i.priority.name }}
                </v-col>
              </v-row>
              <v-row>
                <v-btn
                  @click="issueJournal(i)"
                  class="mb-2"
                  color="primary"
                  icon
                >
                  <v-icon v-if="expandedIssue && expandedIssue.id == i.id">
                    mdi-arrow-up
                  </v-icon>
                  <v-icon v-else> mdi-arrow-down-circle </v-icon>
                </v-btn>
                <v-col class="text-pre-wrap">
                  <span v-html="replaceNewLines(i.description)"> </span>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  expandedIssue &&
                  expandedIssue.id == i.id &&
                  expandedIssue.journals &&
                  !expandedIssue.is_private
                "
              >
                <v-col v-if="!expandedIssue.private_notes">
                  <v-card
                    class="mb-3"
                    v-for="i in expandedIssue.journals"
                    :key="i.id"
                  >
                    <v-card-title> {{ i.user.name }} </v-card-title>
                    <v-card-text class="text-pre-wrap">
                      <span v-html="replaceNewLines(i.notes)"> </span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <accept-dialog
              v-model="doneDialog"
              v-if="doneDialog"
              title="Issue done?"
              text="Are you sure you want to finnish this issue?"
              @accepted="issueDone(selectedIssue)"
            >
            </accept-dialog>

            <accept-dialog
              v-model="deleteDialog"
              v-if="deleteDialog"
              title="Delete Issue?"
              text="Are you sure you want to delete this issue?"
              @accepted="delIssue(getSelectedProject.id)"
            >
            </accept-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="showEditIssueDialog">
      <editIssuesDialog
        @edit-done="
          showEditIssueDialog = false;
          selectedIssue = {};
        "
        v-if="showEditIssueDialog"
        :issue="selectedIssue"
        :project_id="getSelectedProject.id"
      >
      </editIssuesDialog>
    </v-dialog>
  </v-card>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import editIssuesDialog from "../../components/admin/redmine/issues/edit_issues.vue";
import acceptDialog from "../../components/helpers/generic_accept_dialog.vue";
import menuTitle from "../../components/helpers/menu_title.vue";
import { verify, decode } from "../../helpers/jwt";

export default {
  name: "Project",
  components: {
    menuTitle,
    editIssuesDialog,
    acceptDialog,
  },
  data() {
    return {
      projectId: this.$route.params.id,
      showEditIssueDialog: false,
      selectedIssue: null,
      deleteDialog: false,
      doneDialog: false,
      expandedIssue: null,
    };
  },
  computed: {
    ...mapGetters({
      getIssuesOfProject: "getIssuesOfProject",
      getSelectedProject: "getSelectedProject",
    }),
  },
  methods: {
    replaceNewLines(note) {
      return note.replace("\n", "<br/>");
    },
    ...mapActions({
      fetchIssuesOfProject: "fetchIssuesOfProject",
      fetchProjectById: "fetchProjectById",
      updateIssue: "updateIssue",
      deleteIssue: "deleteIssue",
      showSnackbar: "showSnackbar",
      fetchIssueWithJournalsOfProject: "fetchIssueWithJournalsOfProject",
    }),
    issueJournal(issue) {
      if (this.expandedIssue == null) {
        this.expandedIssue = issue;
      } else {
        this.expandedIssue.id == issue.id
          ? (this.expandedIssue = null)
          : (this.expandedIssue = issue);
      }
      if (issue && !issue.journals) {
        this.fetchIssueWithJournalsOfProject(issue.id).then((v) => {
          this.expandedIssue = v;
        });
      }
    },
    getLogedInUser: () => verify(localStorage.getItem("jwt")),
    delIssue(project_id) {
      this.deleteIssue({
        issue_id: this.selectedIssue.id,
        project_id: project_id,
      }).then((error) => {
        if (error) {
          this.showSnackbar("Issue could not be deleted. Error: " + error);
        } else {
          this.showSnackbar("Issue has been deleted");
          this.deleteDialog = false;
          this.selectedIssue = null;
        }
      });
    },
    issueDone: function (issue) {
      issue.status = { id: 5, name: "Done" };
      issue.notes = "Thanks it is done";
      this.updateIssue(issue).then((error) => {
        if (error) {
          this.showSnackbar("Issue could not be updated. Error: " + error);
        } else {
          this.showSnackbar("Issue has been updated");
          this.doneDialog = false;
          this.selectedIssue = null;
        }
      });
    },
  },
  async mounted() {
    await this.fetchIssuesOfProject(this.getLogedInUser().projectid);
    await this.fetchProjectById(this.getLogedInUser().projectid);
  },
};
</script>
  
<style>
</style>